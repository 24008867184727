<div class="container-fluid">
    <div class="row" *ngIf="notifications && notifications.length > 0; else noDataToShow">
        <div class="col-sm-12 col-xl-12" *ngFor="let notification of notifications; let i = index;">
            <div class="card ribbon-wrapper shadow-0 border p-0">
                <div class="card-body" style="padding: 30px !important">
                    <h6>{{notification?.notification}}.</h6>
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div
                                    class="span badge badge-pill pill-badge-success cursor-pointer"
                                    style="margin-top: 10px">{{notification?.created_at}}</div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div
                                    class="span badge badge-pill pill-badge-primary cursor-pointer pull-right text-capitalize"
                                    style="margin-top: 10px">By - College</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noDataToShow>
        <div class="row text-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card ribbon-wrapper shadow-0 border p-0">
                    <div class="card-body">
                        <h5 class="p-100px mt-15px font-weight-bold">No data to show</h5>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="div">
        <div class="col-xs-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ngb-pagination class="d-flex justify-content-center" (pageChange)="onPageChange($event)"
                            [(page)]="pagination.page"
                            [boundaryLinks]="true"
                            [collectionSize]="pagination.total"
                            [maxSize]="5"
                            [pageSize]="pagination.perpage">
            </ngb-pagination>
        </div>
    </div>
</div>
